import React from "react";
import "./reset.css";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import GA4React from "ga-4-react";
//import ReactPixel from 'react-facebook-pixel';

import Loading from "./Components/loading";
import { Router } from "./router";
//ReactPixel.init('184622753985736', { accessToken: 'EAAHBglaM8kUBAMmWhvX2HDhtGVl6HW2xR3BZBzOfNUQSSC82rkBe8h7n1sJTJ9Bt2rzBJRiD79T8TxUIRZBf8rg9Uxcvprf2Xtux3CbpmC2e7dc5l4ZCq8lkiGCyLsppybEFf3H9XPHZA32YaqrrgFBKsUgxZAMSXA86uHch2oZAblgyVPrYClKFMrAQNNWZCkZD' });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>  
    <BrowserRouter>
      <ScrollToTop /> 
      <Loading />
      <Router />
    </BrowserRouter>
  </React.StrictMode>
);

try {
  setTimeout(_ => {
    const ga4react = new GA4React("G-4BFJEEHSGT");
    ga4react.initialize().catch(err => console.error(err));
  }, 4000);
} catch (err) {
    console.error(err);
}