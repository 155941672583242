import styled from "styled-components";
import { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";

export default function Loading() {
    
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const Container = styled.div`
    .LoadingIcon {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      background-color: var(--background-color);
      position: fixed;
      z-index: 999;
    }
  `;

  return (
    <Container>
      {loading ? (
        <div className="LoadingIcon">
          <ClipLoader  
            color={"var(--verde-color)"}
            loading={loading}            
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <></>
      )}
    </Container>
  );
}